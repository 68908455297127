<template>
  <div class="container home">
    <!-- v-if="uploadEnabled" -->
    <br>

    <!-- <hr> -->

    <div class="row justify-content-center">
      <div class="col col-md-8">
        <div class="upload-panel" v-if="isLoggedIn">
          <!-- && currentUser.role && currentUser.role != 'role_user_normal' -->
          <h1>
            {{ $t('MATCH_NEW.title') }}
          </h1>
          <p>
            <span v-html="$t('MATCH_NEW.subtitle')"></span>
          </p>

          <div class="droparea">
            <vue-dropzone
              :awss3="awss3"
              @vdropzone-s3-upload-error="s3UploadError"
              @vdropzone-s3-upload-success="s3UploadSuccess"
              @vdropzone-sending="sendingEvent"
              @vdropzone-file-added="fileAddedEvent"
              @vdropzone-total-upload-progress="progressEvent"
              :options="dropzoneOptions"
              ref="awsDropzone"
              id="awsDropzone"
            ></vue-dropzone>
            <div class="overlay" v-if="uploadEnabled == false"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "home",
  metaInfo() {
    return {
      title: "Enviar partida"
    };
  },
  computed: {
    authorizationHeader: function() {
      return { Authorization: "Bearer " + localStorage.getItem("token") };
    }
  },
  data: function() {
    return {
      uploadEnabled: true,
      token: "DEMOFIGHTBACKBETA",
      dropzoneOptions: {
        url: `${this.$apiURL}/api/v1/posted`,
        maxFilesize: 180,
        dictDefaultMessage: "upload aqui",
        maxFiles: 1,
        acceptedFiles: ".dem"
      },
      awss3: {
        signingURL: `${this.$apiURL}/api/v1/presign`,
        sendFileToServer: true,
        withCredentials: false,
        params: {}
      }
    };
  },
  methods: {
    initialize() {
      this.dropzoneOptions.headers = this.authorizationHeader;
      this.awss3.headers = this.authorizationHeader;
    },
    s3UploadError(error, request) {
      // console.log(error, request);
      this.$notify({
        group: "all",
        text: "Você já enviou o máximo de demos deste mês",
        type: "info",
        duration: -1000
      });
      this.$router.push("/");
      // this.uploadEnabled = true;
    },
    s3UploadSuccess(s3ObjectLocation) {
      let demourl = s3ObjectLocation.replace("%2F", "/");
      // this.uploadEnabled = true
      this.$http
        .post("api/v1/s3uploaded", { demourl: demourl })
        .then(response => {
          this.$notify({
            group: "all",
            text:
              "Estamos processando sua partida, você receberá uma Notificação quando estiver pronto",
            type: "success",
            duration: -1000
          });
          setTimeout(() => {
            this.$router.push(`/matches/${response.data.match_id}`);
          }, 2000);
        });
    },
    sendingEvent(obj1, obj2) {
      this.uploadEnabled = false;
      // console.log(obj2);
      setTimeout(() => {
        obj2.abort();
      }, 250);
    },
    fileAddedEvent(file) {
      this.uploadEnabled = false;
    },

    progressEvent( totaluploadprogress, totalBytes, totalBytesSent) {
      console.log(totaluploadprogress, totalBytes, totalBytesSent);
    }

  },
  mounted() {
    this.initialize();
  },
  components: {
    vueDropzone: vue2Dropzone
  }
};
</script>

<style lang="sass" scoped>
.droparea
  position: relative
  height: 280px
  overflow: hidden
  .vue-dropzone
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 280px
    z-index: 2
  .overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 280px
    z-index: 30
    background: rgba(0, 0, 0, .5)
    &::after
      position: absolute
      top: 50%
      left: 50%
      width: 200px
      height: 200px
      margin-left: -100px
      margin-top: -100px
      content: 'AGUARDE, ENVIANDO DEMO'
      color: white
      z-index: 31
      font-size: 28px
      font-weight: bold
      text-align: center
      display: flex
      justify-content: center
      align-items: center

</style>

